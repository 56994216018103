import { ANIMATION_DURATION } from "App";
import useWindowSize from "hook/useWindowSize";
import R from "res/R";
import { twMerge } from "tailwind-merge";
import { useInView } from "react-intersection-observer";
const Article5 = () => {
  const { width: windowWidth } = useWindowSize();
  const { ref, inView } = useInView();
  return (
    <div
      ref={ref}
      className={twMerge(
        "relative flex h-full flex-col justify-center  overflow-y-hidden bg-black py-[15.625rem] text-center",
        " mobile:h-full mobile:py-20"
      )}
    >
      <figure
        className={twMerge(
          "absolute left-1/2 top-[60%] w-[80%] -translate-x-1/2 -translate-y-1/2",
          "mobile:w-[1200px]"
        )}
      >
        <img src={R.images.our_projects_tv3x} alt={"radial gradient"} />
      </figure>
      <span
        style={{
          animationDelay: `${1 * ANIMATION_DURATION}ms`,
          transitionDelay: `${1 * ANIMATION_DURATION}ms`,
        }}
        className={twMerge(
          "relative mb-[1.875rem]  inline-block   text-[1.875rem] font-semibold text-purple-100",
          "mobile:mb-[1.25rem] mobile:text-[1.25rem]",
          inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
        )}
      >
        {"Our  Projects"}
      </span>
      <div
        style={{
          animationDelay: `${2 * ANIMATION_DURATION}ms`,
          transitionDelay: `${2 * ANIMATION_DURATION}ms`,
        }}
        className={twMerge(
          "relative mb-[3.75rem]  text-[3.75rem] font-semibold leading-[5rem] text-white",
          "mobile:px-10 mobile:text-[2.5rem] mobile:leading-[3.5rem]",
          inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
        )}
      >
        {"Leading the transition\nfor Metaverse projects"}
      </div>
      <div
        style={{
          animationDelay: `${3 * ANIMATION_DURATION}ms`,
          transitionDelay: `${3 * ANIMATION_DURATION}ms`,
        }}
        className={twMerge(
          "mx-auto max-w-[1300px] px-[10.75rem]",
          "mobile:px-0",
          "maxDt:max-w-[1700px]",
          inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
        )}
      >
        <figure className={twMerge("relative z-10 mx-auto  h-full w-full ")}>
          <img
            src={
              windowWidth >= 1024
                ? R.images.our_projects_iv3x
                : R.images.our_projects_iv_m3x
            }
            alt={"project iv"}
          />
        </figure>
      </div>
    </div>
  );
};

export default Article5;
