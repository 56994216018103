import React from "react";
import R from "../res/R";
import { twMerge } from "tailwind-merge";
import { useInView } from "react-intersection-observer";
import useWindowSize from "hook/useWindowSize";

const Article1 = () => {
  const { ref, inView } = useInView();
  const { width: windowWidth } = useWindowSize();
  return (
    <div
      ref={ref}
      className={twMerge("relative  h-[100vh] w-full overflow-hidden bg-black")}
    >
      <div
        className={twMerge(
          "absolute left-0 top-0 h-[100vh] w-full  border border-b-black bg-[image:var(--image-url)]  bg-cover bg-center",
          "mobile:bg-[70%] mobile:px-10",
          inView && "animate-scale-down"
        )}
        style={
          {
            "--image-url": `url(${R.images.reimagine_top_iv_bg3x})`,
          } as React.CSSProperties
        }
      />

      <figure
        className={twMerge(
          "relative mx-auto h-[1.125rem] w-[12.625rem] pt-10 text-center",
          "mobile:w-[8.375rem] mobile:pt-[1.875rem]"
        )}
      >
        <img src={R.images.reimagine_top_iv_logo3x} alt={"reimagine logo"} />
      </figure>
      <figure
        className={twMerge(
          "absolute left-[10%] top-[60%] w-[60vh] origin-[center] ",
          windowWidth < 1200 && "w-[50vh]",
          "mobile:top-[13%] mobile:w-[18.43rem]",
          inView && "animate-swing-in-bottom"
        )}
      >
        <img src={R.images.reimagine_top_iv_txt3x} alt={"reimagine txt"} />
      </figure>
    </div>
  );
};

export default Article1;
