import { ANIMATION_DURATION } from "App";
import React from "react";
import { useInView } from "react-intersection-observer";
import R from "res/R";
import { twMerge } from "tailwind-merge";

const Article6 = () => {
  const { ref, inView } = useInView();

  const textList = ["Join us ", "on a journey\n", "to the Metaverse"];

  return (
    <div
      className={twMerge(
        "relative flex  h-[100vh] w-full flex-col overflow-hidden px-[10.75rem]",
        "mobile:h-[52rem] mobile:px-10",
        "maxDt:justify-between maxDt:pt-28"
      )}
    >
      <div
        ref={ref}
        className={twMerge(
          "absolute left-0 top-0 h-[100vh] w-full  bg-cover bg-center",
          " mobile:h-[52rem] mobile:bg-bottom mobile:px-10",
          "bg-[image:var(--image-url)]",
          inView && "animate-scale-down"
        )}
        style={
          {
            "--image-url": `url(${R.images.lets_go_bg3x})`,
            "--mobile-image-url": `url(${R.images.lets_go_bg_m3x})`,
          } as React.CSSProperties
        }
      />
      <div
        className={twMerge(
          "relative pt-[5vh] text-center text-[3.75rem] font-semibold leading-[5rem] text-white",
          "mobile:pt-[5vh] mobile:text-center mobile:text-[2.5rem] mobile:leading-[3.5rem]"
        )}
      >
        {textList.map((text, i) => {
          const animationIndex = i + 2;

          return (
            <span
              key={i}
              style={{
                animationDelay: `${animationIndex * ANIMATION_DURATION}ms`,
                transitionDelay: `${animationIndex * ANIMATION_DURATION}ms`,
              }}
              className={twMerge(
                `${inView ? "animate-fade-in-up opacity-100" : "opacity-0"}`
              )}
            >
              {text}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Article6;
