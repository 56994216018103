import { ANIMATION_DURATION } from "App";
import React from "react";
import R from "res/R";
import { twMerge } from "tailwind-merge";
import { useInView } from "react-intersection-observer";

const Article3 = () => {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      className={twMerge(
        "relative   h-[50.5rem]   w-full items-center overflow-hidden bg-[image:var(--image-url)] bg-cover  text-center ",
        "mobile:h-[93vh] mobile:bg-top mobile:px-10"
      )}
      style={
        {
          "--image-url": `url(${R.images.our_business_bg3x})`,
        } as React.CSSProperties
      }
    >
      <div
        className={twMerge(
          "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2",
          "mobile:top-20 mobile:w-[80%] mobile:translate-y-0 "
        )}
      >
        <div
          style={{
            animationDelay: `${1 * ANIMATION_DURATION}ms`,
            transitionDelay: `${1 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            `${inView ? "animate-fade-in-up opacity-100" : "opacity-0"}`
          )}
        >
          <figure
            className={twMerge(
              " mx-auto mb-[1.875rem] w-[12.625rem]",
              "mobile:mb-[1.25rem] mobile:w-[8.375rem]"
            )}
          >
            <img src={R.images.our_business_iv_logo3x} alt={"business logo"} />
          </figure>
        </div>
        <div
          style={{
            animationDelay: `${2 * ANIMATION_DURATION}ms`,
            transitionDelay: `${2 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            `${inView ? "animate-fade-in-up opacity-100" : "opacity-0"}`
          )}
        >
          <p
            className={twMerge(
              "text-center text-[3.75rem] font-semibold leading-[5rem] text-white",
              "mobile:text-[2.5rem] mobile:leading-[3rem] "
            )}
          >
            {"Our business area to create\na better world"}
          </p>
        </div>
      </div>
      <div
        className={twMerge(
          "relative  left-1/2 h-[50.5rem] w-full max-w-[2200px] -translate-x-1/2",
          "mobile:absolute mobile:bottom-0 mobile:bg-top mobile:px-10"
        )}
      >
        <div
          style={{
            animationDelay: `${1 * ANIMATION_DURATION}ms`,
            transitionDelay: `${1 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "absolute left-[6rem] top-[5rem]  h-[6%] w-[6%] ",
            "mobile:hidden",
            inView ? "animate-slide-in-left-down" : "opacity-0"
          )}
        >
          <img src={R.images.our_business_iv_23x} alt={"business iv 2"} />
        </div>
        <div
          style={{
            animationDelay: `${2 * ANIMATION_DURATION}ms`,
            transitionDelay: `${2 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "absolute bottom-[-13vh] left-[-13vh]  h-[31.5rem] w-[31.5rem] ",
            "mobile:hidden",
            inView ? "animate-slide-in-left-up" : "opacity-0"
          )}
        >
          <img src={R.images.our_business_iv_13x} alt={"business iv 1"} />
        </div>
        <div
          style={{
            animationDelay: `${2 * ANIMATION_DURATION}ms`,
            transitionDelay: `${2 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "absolute bottom-[2.5vh] right-[-12vh] h-[40.375rem] w-[46.13rem]",
            "mobile:bottom-[0rem] mobile:right-[-8rem]  mobile:h-auto mobile:w-[68vh]",
            inView ? "animate-slide-in-right-up" : "opacity-0"
          )}
        >
          <img src={R.images.our_business_iv_33x} alt={"business iv 3"} />
        </div>
      </div>
    </div>
  );
};

export default Article3;
