import { useEffect, useState } from "react";

export const DeviceMode_e = {
  PC: "PC",
  TABLET: "TABLET",
  MOBILE: "MOBILE",
  UNKNOWN: "UNKNOWN",
} as const;

const useWindowSize = () => {
  const [hWindowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return hWindowSize;
};

export default useWindowSize;
