// don't modify manually
// this file is automatically generated by brewImages.ts

import icon_our_primary_iv_13x from '../static/images/icon/our-primary-iv-1@3x.png';
import icon_our_primary_iv_23x from '../static/images/icon/our-primary-iv-2@3x.png';
import icon_our_primary_iv_33x from '../static/images/icon/our-primary-iv-3@3x.png';
import icon_our_primary_iv_arrow_m3x from '../static/images/icon/our-primary-iv-arrow-m@3x.png';
import icon_our_primary_iv_arrow3x from '../static/images/icon/our-primary-iv-arrow@3x.png';
import icon_our_primary_iv_circle3x from '../static/images/icon/our-primary-iv-circle@3x.png';
import icon_we_offer_iv_21x from '../static/images/icon/we_offer_iv_2.png';
import lets_go_bg_m3x from '../static/images/lets-go-bg-m@3x.png';
import lets_go_bg3x from '../static/images/lets-go-bg@3x.png';
import logo3x from '../static/images/logo@3x.png';
import our_business_bg3x from '../static/images/our-business-bg@3x.png';
import our_business_iv_13x from '../static/images/our-business-iv-1@3x.png';
import our_business_iv_23x from '../static/images/our-business-iv-2@3x.png';
import our_business_iv_33x from '../static/images/our-business-iv-3@3x.png';
import our_business_iv_logo3x from '../static/images/our-business-iv-logo@3x.png';
import our_primary_iv_logo_13x from '../static/images/our-primary-iv-logo-1@3x.png';
import our_primary_iv_logo_2_m3x from '../static/images/our-primary-iv-logo-2-m@3x.png';
import our_primary_iv_logo_21x from '../static/images/our-primary-iv-logo-2.png';
import our_primary_iv_logo_23x from '../static/images/our-primary-iv-logo-2@3x.png';
import our_projects_iv_m3x from '../static/images/our-projects-iv-m@3x.png';
import our_projects_iv3x from '../static/images/our-projects-iv@3x.png';
import our_projects_tv3x from '../static/images/our-projects-tv@3x.png';
import pte_ltd3x from '../static/images/pte-ltd@3x.png';
import reimagine_top_iv_bg_m3x from '../static/images/reimagine-top-iv-bg-m@3x.png';
import reimagine_top_iv_bg3x from '../static/images/reimagine-top-iv-bg@3x.png';
import reimagine_top_iv_logo3x from '../static/images/reimagine-top-iv-logo@3x.png';
import reimagine_top_iv_txt3x from '../static/images/reimagine-top-iv-txt@3x.png';

const images = {
  icon_our_primary_iv_13x,
  icon_our_primary_iv_23x,
  icon_our_primary_iv_33x,
  icon_our_primary_iv_arrow_m3x,
  icon_our_primary_iv_arrow3x,
  icon_our_primary_iv_circle3x,
  icon_we_offer_iv_21x,
  lets_go_bg_m3x,
  lets_go_bg3x,
  logo3x,
  our_business_bg3x,
  our_business_iv_13x,
  our_business_iv_23x,
  our_business_iv_33x,
  our_business_iv_logo3x,
  our_primary_iv_logo_13x,
  our_primary_iv_logo_2_m3x,
  our_primary_iv_logo_21x,
  our_primary_iv_logo_23x,
  our_projects_iv_m3x,
  our_projects_iv3x,
  our_projects_tv3x,
  pte_ltd3x,
  reimagine_top_iv_bg_m3x,
  reimagine_top_iv_bg3x,
  reimagine_top_iv_logo3x,
  reimagine_top_iv_txt3x
}

export default images
