import { useEffect } from "react";
import "./App.css";
import Article1 from "./component/Article1";
import Article2 from "component/Article2";
import Article3 from "component/Article3";
import Article4 from "component/Article4";
import Article5 from "component/Article5";
import Article6 from "component/Article6";
import { twMerge } from "tailwind-merge";
import AppEnv from "conf/appEnv";

export const ANIMATION_DURATION = 300;

function App() {
  useEffect(() => {
    AppEnv.print();
  }, []);

  return (
    <div className={twMerge("app bg-black font-en")}>
      <Article1 />
      <Article2 />
      <Article3 />
      <Article4 />
      <Article5 />
      <Article6 />
      <div
        className={twMerge(
          "border-t border-grey-80 bg-[#111111] py-[2.469rem] text-center text-[1.25rem] font-light text-white",
          "mobile:py-[1.813rem] mobile:text-[0.75rem]"
        )}
      >
        {"Copyright ©POST VOYAGER PTE. LTD. All rights reserved."}
      </div>
    </div>
  );
}

export default App;
