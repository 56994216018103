import { PropsWithChildren } from "react";
import R from "res/R";
import { twMerge } from "tailwind-merge";
import { useInView } from "react-intersection-observer";

const ANIMATION_DURATION = 100;
const Article2 = () => {
  const { ref, inView } = useInView();

  return (
    <div
      className={twMerge(
        "mx-auto flex h-full flex-col items-center justify-center border border-t-black bg-black px-[5rem]  py-[15.625rem]",
        "mobile:h-full mobile:px-10 mobile:py-20"
      )}
    >
      <div ref={ref} className={twMerge("w-full max-w-[1320px]")}>
        <div
          style={{
            animationDelay: `${1 * ANIMATION_DURATION}ms`,
            transitionDelay: `${1 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "mb-[1.825rem] text-center text-[1.825rem] font-semibold text-purple-100 transition-opacity",
            "mobile:mb-[1.25rem] mobile:text-[1.25rem]",
            inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
          )}
        >
          {"Our primary mission"}
        </div>
        <div
          style={{
            animationDelay: `${2 * ANIMATION_DURATION}ms`,
            transitionDelay: `${2 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "mb-20 text-center text-[3.75rem] font-semibold leading-[4.75rem] text-white transition-opacity",
            "mobile:mb-[2.5rem] mobile:text-[2.5rem] mobile:leading-[3rem]",
            inView ? "opacity-1 opacity-1 animate-fade-in-up" : "opacity-0 "
          )}
        >
          {"Creating a better world\nthrough virtual spaces"}
        </div>
        <div
          style={{
            animationDelay: `${3 * ANIMATION_DURATION}ms`,
            transitionDelay: `${3 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "mb-[7.5rem] text-center text-[1.5rem] text-white transition-opacity",
            "mobile:mb-[2.5rem] mobile:whitespace-normal mobile:text-[1.125rem]",
            inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
          )}
        >
          <span>
            {
              "Our core mission is to facilitate the seamless transition of innovative projects from\ntraditional services to the Metaverse."
            }
          </span>
          <span className={twMerge("mobile:mt-[1.875rem] mobile:inline-block")}>
            {
              "To achieve this, our skilled development and\nmanagement teams provide comprehensive support in planning and technical expertise."
            }
          </span>
        </div>
        <div
          className={twMerge(
            "flex  text-left text-white",
            "mobile:w-full mobile:flex-col"
          )}
        >
          <PrimaryBox
            delayNumber={6}
            image={R.images.icon_our_primary_iv_13x}
            className={twMerge(
              "flex-[2]",
              inView ? "opacity-1 animate-fade-in" : "opacity-0"
            )}
          >
            <figure
              className={twMerge(
                "mb-[1.75rem]",
                "mobile:mb-[1.25rem] mobile:w-[9.75rem]"
              )}
            >
              <p
                className={twMerge(
                  "text-[1.9rem] leading-7",
                  "mobile:text-[1.4rem] mobile:leading-6"
                )}
              >
                {"Web2 Services"}
              </p>
            </figure>
            <p
              className={twMerge(
                "text-[1.25rem] leading-7",
                "mobile:text-[1rem] mobile:leading-6"
              )}
            >
              {"Innovating with our partners'\nintellectual assets"}
            </p>
          </PrimaryBox>
          <PrimaryBox
            delayNumber={6}
            image={R.images.icon_our_primary_iv_23x}
            className={twMerge(
              "flex-[2.3]",
              inView ? "opacity-1 animate-fade-in" : "opacity-0"
            )}
          >
            <figure
              className={twMerge(
                "mb-[1.75rem] w-[13.5rem]",
                "mobile:mb-[1.25rem] mobile:w-[9.75rem]"
              )}
            >
              <img
                src={R.images.our_primary_iv_logo_23x}
                alt={"primary logo"}
              />
            </figure>
            <p
              className={twMerge(
                "text-[1.25rem]  leading-7",
                "mobile:text-[1rem] mobile:leading-6"
              )}
            >
              {"Streamlined Optimization\n& End-to-End Solutions"}
            </p>
          </PrimaryBox>
          <figure
            style={{
              animationDelay: `${8 * ANIMATION_DURATION}ms`,
              transitionDelay: `${8 * ANIMATION_DURATION}ms`,
            }}
            className={twMerge(
              "mr-[1.875rem] h-[1.5rem]  w-[2.5rem] self-center ",
              "mobile:relative mobile:left-1/2 mobile:mb-12 mobile:mr-0 mobile:mt-8 mobile:-translate-x-1/2 mobile:rotate-90 mobile:self-baseline mobile:pt-0",
              inView ? "opacity-1 animate-fade-in" : "opacity-0",
              "desktop:pt-[4rem]"
            )}
          >
            <img src={R.images.icon_our_primary_iv_arrow3x} alt={"arrow"} />
          </figure>
          <PrimaryBox
            delayNumber={8}
            image={R.images.icon_our_primary_iv_33x}
            className={twMerge(
              " mr-0 flex-[3.1]",
              inView ? "opacity-1 animate-fade-in" : "opacity-0"
            )}
          >
            <div
              className={twMerge(
                "text-[1.875rem] font-medium leading-[2.5rem]",
                "mt-[-0.5rem] mobile:text-[1.5rem] mobile:leading-[1.875rem]"
              )}
            >
              {"Transitioning to\nMetaverse "}
              <span className={twMerge("mobile:block")}>{"Services"}</span>
            </div>
          </PrimaryBox>
        </div>
      </div>
    </div>
  );
};

const PrimaryBox = ({
  image,
  delayNumber,
  className,
  children,
}: {
  image: string;
  delayNumber: number;
  className?: string;
} & PropsWithChildren) => {
  return (
    <div
      style={{
        animationDelay: `${delayNumber * ANIMATION_DURATION}ms`,
        transitionDelay: `${delayNumber * ANIMATION_DURATION}ms`,
      }}
      className={twMerge(
        "mr-[1.875rem]  rounded-[20px] border border-[#eeeeee] bg-purple-100/60 px-[1.875rem] pb-10 pt-[1.875rem]",
        "mobile:mb-5 mobile:mr-0  mobile:self-stretch mobile:pb-[1.875rem]",
        className
      )}
    >
      <figure
        className={twMerge(
          "mb-[8.25rem] h-[3.125rem] w-[3.125rem]",
          "mobile:mb-20 mobile:h-10 mobile:w-10"
        )}
      >
        <img src={image} alt={image} />
      </figure>
      <div>{children}</div>
    </div>
  );
};
export default Article2;
