import { ANIMATION_DURATION } from "App";
import { twMerge } from "tailwind-merge";
import { useInView } from "react-intersection-observer";
import R from "res/R";
const Article4 = () => {
  const { ref, inView } = useInView();

  return (
    <div
      className={twMerge(
        "flex h-full flex-col justify-center  bg-purple-100 px-[5rem] py-[15.625rem]",
        " mobile:h-full mobile:px-10 mobile:py-20"
      )}
    >
      <div ref={ref}>
        <span
          style={{
            animationDelay: `${1 * ANIMATION_DURATION}ms`,
            transitionDelay: `${1 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "mb-[1.875rem] inline-block  w-full bg-purple-100  px-[10.75rem] text-center text-[1.875rem] font-semibold text-white",
            "mobile:mb-[1.25rem] mobile:px-10 mobile:text-[1.25rem]",
            inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
          )}
        >
          {"What We Offer"}
        </span>
        <div
          style={{
            animationDelay: `${2 * ANIMATION_DURATION}ms`,
            transitionDelay: `${2 * ANIMATION_DURATION}ms`,
          }}
          className={twMerge(
            "mb-[7.5rem] text-center text-[3.75rem] font-semibold leading-[5rem]  text-white",
            "mobile:mb-[3.75rem] mobile:text-[2.5rem] mobile:leading-[3rem]",
            inView ? "opacity-1 animate-fade-in-up" : "opacity-0"
          )}
        >
          {"Transitioning original games\nto the Metaverse"}
        </div>
        <div
          className={twMerge(
            "mx-auto flex h-[25rem]  w-full max-w-[1320px]  text-left",
            "mobile:h-full mobile:flex-col"
          )}
        >
          <div
            style={{
              animationDelay: `${3 * ANIMATION_DURATION}ms`,
              transitionDelay: `${3 * ANIMATION_DURATION}ms`,
            }}
            className={twMerge(
              "mr-[3.75rem] flex shrink-[1] flex-grow  flex-col items-start justify-between rounded-[20px] border border-[#ddd] bg-black/30 p-[3.125rem]",
              "mobile:mb-[1.875rem] mobile:mr-0 mobile:items-center mobile:p-[2.5rem]",
              inView ? "opacity-1 animate-fade-in" : "opacity-0"
            )}
          >
            <span
              className={twMerge(
                "inline-grid grid-cols-2 gap-1",
                "mobile:mb-[2.5rem]"
              )}
            >
              <span
                className={twMerge(
                  "inline-block h-7 w-7 rounded-[4px] bg-white",
                  "mobile:h-5 mobile:w-5 "
                )}
              />
              <span
                className={twMerge(
                  "inline-block h-7 w-7 origin-[28px_28px] animate-slope-slow rounded-[4px] bg-purple-100",
                  "mobile:h-5 mobile:w-5 mobile:origin-[1.25rem_1.25rem]"
                )}
              />
              <span
                className={twMerge(
                  "inline-block h-7 w-7 rounded-[4px] bg-white",
                  "mobile:h-5 mobile:w-5"
                )}
              />
              <span
                className={twMerge(
                  "inline-block h-7 w-7 rounded-[4px] bg-white",
                  "mobile:h-5 mobile:w-5"
                )}
              />
            </span>
            <div
              className={twMerge(
                "text-[2.125rem] font-semibold text-white",
                "mobile:text-center mobile:text-[1.875rem]"
              )}
            >
              {"Consulting\n"}
              <span className={twMerge("mobile:block")}>{"& "}</span>
              {"Advisory Services"}
            </div>
          </div>
          <div
            style={{
              animationDelay: `${4 * ANIMATION_DURATION}ms`,
              transitionDelay: `${4 * ANIMATION_DURATION}ms`,
            }}
            className={twMerge(
              "flex flex-grow flex-col items-start justify-between rounded-[20px] border border-[#ddd] bg-black/30 p-[3.125rem]",
              "mobile:items-center mobile:p-[2.5rem]  mobile:text-center",
              inView ? "opacity-1 animate-fade-in" : "opacity-0"
            )}
          >
            <div
              className={twMerge(
                "relative left-[-4px] top-[-9px] block h-[4.5rem] w-[4.5rem] animate-spin-slow overflow-visible",
                "mobile:top-0 mobile:mb-[2.5rem] mobile:h-[3.5rem] mobile:w-[3.5rem]"
              )}
            >
              <img src={R.images.icon_we_offer_iv_21x} alt={"rotate box"} />
            </div>
            <div
              className={twMerge(
                "text-[2.125rem] font-semibold  text-white",
                "mobile:text-center mobile:text-[1.875rem]"
              )}
            >
              {"Business Solutions\n"}
              <span className={twMerge("mobile:block")}>{"& "}</span>
              {"Technology"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article4;
