import images from "./images";

const public_res = {
  // star_1: "/img/start_1.png" as const,
  // start_2: "/img/start_2.png" as const,
  // books: "/img/Books_Magazines.gltf" as const,
};

const R = {
  public_res,
  images,
};

export default R;
