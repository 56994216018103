export default class AppEnv {
  static get appname() {
    return process.env.REACT_APP_NAME as string;
  }
  static get version() {
    return process.env.REACT_APP_VERSION as string;
  }
  static get versionCode() {
    return process.env.REACT_APP_VERSION_CODE as string;
  }
  static get githash() {
    return process.env.REACT_APP_GIT_HASH as string;
  }
  static get builddate() {
    return process.env.REACT_APP_BUILD_DATE as string;
  }
  static get isDevelopment() {
    return process.env.NODE_ENV === "development";
  }
  static get isProduction() {
    return process.env.NODE_ENV === "production";
  }

  static print() {
    const appInfo = `${AppEnv.appname} ${AppEnv.version} ${AppEnv.githash} ${AppEnv.builddate} ${
      !AppEnv.isDevelopment ? AppEnv.versionCode : ""
    }`;
    console.warn(appInfo);
  }
}
